import {GnsElement} from '../../../elements/interfaces/booking-bar.interface';

export type CurrView = 'exterior' | 'interior';

export interface ModelVehicleResponse {
    free: number;
    preorder: number;
    image_sale: string;
    images: string[];
    image_map: string;
    image_model: string;
    modelId: number;
    manufacturer: string;
    project: string;
    model: string;
    price: number;
}

export interface ModelVehicle {
    ident: string;
    title: string;
    count: number;
    image: string;

}

export interface ModelEngineDetails {
    name: string;
    categories: ModelEquipmentEngineDetails[];
}

export interface ModelEquipmentEngineDetails extends ModelEquipmentEngineInfoCategory {
    details: ModelEquipmentEngineInfoItem[];
}

export interface ModelEquipmentEngineInfoCategory {
    id: number;
    name: string;
    opened?: boolean;
}

export interface ModelEquipmentEngineInfoItem {
    name: string;
    categoryId: number;
    value: string;
    sort: number;
}

export interface ModelEngineInfo {
    feature: ModelEquipmentEngineInfoItem[];
    specification: ModelEquipmentEngineInfoItem[];
    specificationCategory: ModelEquipmentEngineInfoCategory[];
    featureCategory: ModelEquipmentEngineInfoCategory[];
}

export interface ModelEquipmentEngine extends GnsElement {
    equipmentInfo: ModelEngineInfo;
    engine: string;
    equipmentEnum: string;
    manufacturer: string;
    model: string;
    modeId: number;
    engineTitle: string;
    engineDescription: string;
    engineEngVol: string;
    engineTransmission: string;
    enginePower: string;
    engineDrivetrain: string;
    engineType: string;
    engineTankOpacity: number;
    engineSeat: number;
    free: number;
    preorder: number;
    price: number;
    mileage: number;
}

export interface ModelEquipmentResponse extends GnsElement {
    _id: string;
    engines: ModelEquipmentEngine[];
    free: number;
    preorder: number;

}

export interface ModelColorExtendCar360 {
    imagePath: string;
    filePrefix: string;
    ext: string;
    inNewWindow: boolean;
    image: string;
    imageBase: string;
    imageName: string;
    frameNum: number;
}

export interface ModelColorExtend {
    car360: ModelColorExtendCar360;
}

export interface ModelColorItem extends GnsElement {
    color: string;
    colorName: string;
    colorHex: string;
    carImage: string;
    galleryColor: string[];
    extend: ModelColorExtend;
    free: number;
    ru: string;
}


export interface ModelExtend {
    icon1: string;
    icon2: string;
    path_360: string;
}

export interface ModelInterior extends GnsElement {
    model?: string;
    interiorName?: string;
    interiorEnum?: string;
    interiorExtend?: ModelExtend;
    interiorColor?: string;
    interiorColorHex?: string;
    interiorImage?: string;
    interiorSquareImage?: string;
    interiorTrim?: string;
    free?: number;
    preorder?: number;
}

export enum BookingModeType {
    Mobility = 'mobility',
    Sale = 'sale'
}

export type BookingMode = 'mobility' | 'sale';

export interface VBookingCodeCheck {
    status?: string;
    guid: string;
    phoneNumber: string;
}
