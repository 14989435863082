import { Vehicle } from './vehicles.interface';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    VehiclesBookingActions,
    VehiclesBookingCancelActions,
    VehiclesBookingCompleteActions, VehiclesBookingPhoneRetryActions, VehiclesBookingRetryActions,
    VehiclesBookingSetPhoneActions,
    VehiclesBookingSmsValidateActions,
    VehiclesLoadActions,
    VehiclesSearchActions,
    VehiclesSelectActions,
    VehiclesSetColorFilterActions,
    VehiclesSetComplicationsFilterActions,
    VehiclesSetModelFilterActions,
} from './vehicles.actions';
import { HttpClient } from '@angular/common/http';
import { VehicleBookingResult } from '../../../elements/client-booking-result/client-booking-status.interface';
import { BookingModeType, VBookingCodeCheck } from '../../models/store/models.interfaces';
import { CheckBookingPhoneService } from '../../../shared/services/check-booking-phone.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CheckBookingClientStatus, ClientStatus } from '../../../shared/models/check-booking.interface';
import { setBookingStatus } from '../../../shared/mapper/booking-status.mapper';
import { MessageBarService } from '../../../shared/message-bar/message-bar.service';

interface VehiclesStateModel {
    vehicles: Vehicle[];
    selected: Vehicle;
    searchVin: string;
    modelFilter: string;
    colorFilter: string;
    complicationFilter: string;
    booking: Vehicle;
    bookingPhone: string;
    bookingStage: VehicleBookingResult;
    bookingCodeCheck: VBookingCodeCheck;
    clientStatus: ClientStatus;
}

const INITIAL_VEHICLES_STATE: VehiclesStateModel = {
    vehicles: [],
    selected: null,
    searchVin: null,
    modelFilter: null,
    colorFilter: null,
    complicationFilter: null,
    booking: null,
    bookingPhone: null,
    bookingStage: null,
    bookingCodeCheck: null,
    clientStatus: null,
};

@State<VehiclesStateModel>({
    name: 'vehicles',
    defaults: INITIAL_VEHICLES_STATE,
})
@Injectable()
export class VehiclesState {
    constructor(
        private httpClient: HttpClient,
        private checkBookingPhoneService: CheckBookingPhoneService,
        private messageBarService: MessageBarService
    ) {
    }

    @Selector()
    public static vehicles(state: VehiclesStateModel): Vehicle[] {
        return state.vehicles
                .filter(vehicle => state.searchVin ? RegExp(state.searchVin).test(vehicle.vin) : true)
                .filter(vehicle => !!state.modelFilter ? vehicle.model === state.modelFilter : true)
                .filter(vehicle => !!state.colorFilter ? vehicle.colorName === state.colorFilter : true)
                .filter(vehicle => !!state.complicationFilter ? vehicle.complitation === state.complicationFilter : true);
    }

    @Selector()
    public static vehicle(state: VehiclesStateModel): Vehicle {
        return state.selected;
    }

    @Selector()
    public static booking(state: VehiclesStateModel): Vehicle {
        return state.booking;
    }

    @Selector()
    public static bookingStage(state: VehiclesStateModel): VehicleBookingResult {
        return state.bookingStage;
    }

    @Selector()
    public static bookingCodeCheck(state: VehiclesStateModel): VBookingCodeCheck {
        return state.bookingCodeCheck;
    }

    @Selector()
    public static clientStatus(state: VehiclesStateModel): ClientStatus {
        return state.clientStatus;
    }

    @Selector()
    public static models(state: VehiclesStateModel): string[] {
        return state.vehicles.map(vehicle => vehicle.model).filter((model, idx, models) => models.indexOf(model) === idx);
    }

    @Selector()
    public static colors(state: VehiclesStateModel): string[] {
        return state.vehicles.map(vehicle => vehicle.colorName).filter((model, idx, models) => models.indexOf(model) === idx);
    }

    @Selector()
    public static complications(state: VehiclesStateModel): string[] {
        return state.vehicles.map(vehicle => vehicle.complitation).filter((model, idx, models) => models.indexOf(model) === idx);
    }

    @Action(VehiclesLoadActions)
    async loadAction({ patchState }: StateContext<VehiclesStateModel>, { payload }: VehiclesLoadActions): Promise<void> {
        const vehicles: Vehicle[] = await this.httpClient.get<Vehicle[]>('/sale-mgr/vehicles', {
            params: {
                search: payload || '',
            },
        }).toPromise().catch(() => []);
        patchState({ vehicles });
        return;
    }

    @Action(VehiclesSelectActions)
    async selectAction({ patchState }: StateContext<VehiclesStateModel>, { payload }: VehiclesSelectActions): Promise<void> {
        patchState({ selected: payload });
        return;
    }

    @Action(VehiclesSetModelFilterActions)
    async setModelFilterActions(
        { patchState }: StateContext<VehiclesStateModel>,
        { payload }: VehiclesSetModelFilterActions
    ): Promise<void> {
        patchState({ modelFilter: payload, selected: null });
        return;
    }

    @Action(VehiclesSetComplicationsFilterActions)
    async setComplicationsFilterActions(
        { patchState }: StateContext<VehiclesStateModel>,
        { payload }: VehiclesSetComplicationsFilterActions
    ): Promise<void> {
        patchState({ complicationFilter: payload, selected: null });
        return;
    }

    @Action(VehiclesSetColorFilterActions)
    async setColorFilterActions(
        { patchState }: StateContext<VehiclesStateModel>,
        { payload }: VehiclesSetColorFilterActions
    ): Promise<void> {
        patchState({ colorFilter: payload, selected: null });
        return;
    }

    @Action(VehiclesSearchActions)
    async searchActions({ patchState }: StateContext<VehiclesStateModel>, { payload }: VehiclesSearchActions): Promise<void> {
        patchState({ searchVin: payload, selected: null });
        return;
    }

    @Action(VehiclesBookingActions)
    async bookingActions({ patchState }: StateContext<VehiclesStateModel>, { payload }: VehiclesBookingActions): Promise<void> {
        patchState({ booking: payload });
        return;
    }

    @Action(VehiclesBookingCancelActions)
    async bookingCancelActions({ patchState }: StateContext<VehiclesStateModel>): Promise<void> {
        patchState({ booking: null, clientStatus: null });
        return;
    }

    @Action(VehiclesBookingSetPhoneActions)  // in list
    bookingSetPhoneActions(
        { patchState }: StateContext<VehiclesStateModel>,
        { payload: bookingPhone }: VehiclesBookingSetPhoneActions
    ): Observable<void | VBookingCodeCheck> {
        patchState({ clientStatus: null });

        return this.checkBookingPhoneService.findClientStatus(bookingPhone).pipe(
            switchMap((bookingClientStatus: CheckBookingClientStatus) => {
                if (bookingClientStatus?.status === ClientStatus.Refused) {
                    patchState({
                        clientStatus: bookingClientStatus?.status,
                    });
                    return EMPTY;
                }

                return this.checkBookingPhoneService.bookingCheckPhone(
                    bookingPhone,
                    BookingModeType.Mobility
                ).pipe(
                    tap((bookingCodeCheck) => {
                        patchState({
                            bookingCodeCheck,
                            bookingStage: setBookingStatus(bookingCodeCheck),
                        });
                    })
                );
            }),
            catchError(() => {
                this.messageBarService.warn('Ошибка выполнения запроса');
                return EMPTY;
            })
        );
    }

    @Action(VehiclesBookingSmsValidateActions)
    async bookingSmsValidateActions(
        { patchState, getState }: StateContext<VehiclesStateModel>,
        { payload }: VehiclesBookingSmsValidateActions
    ): Promise<void> {
        const { selected, bookingCodeCheck } = getState();
        const data = {
            vehicle_id: selected._id,
            phoneNumber: bookingCodeCheck.phoneNumber,
            code: payload,
            guid: bookingCodeCheck.guid,
            product: 'GENESIS',
        };
        const bookingResult = await this.httpClient
                .put<VehicleBookingResult>('/sale-mgr/booking-v-id', data).toPromise();
        patchState({ bookingStage: bookingResult });
    }

    @Action(VehiclesBookingCompleteActions)
    bookingCompleteActions({ patchState, dispatch }: StateContext<VehiclesStateModel>): void {
        patchState({
            selected: null,
            searchVin: null,
            booking: null,
            bookingPhone: null,
            bookingStage: null,
            bookingCodeCheck: null,
        });
        dispatch(new VehiclesLoadActions(null)).subscribe();
    }
    @Action(VehiclesBookingRetryActions)
    bookingRetryActions({ patchState, dispatch }: StateContext<VehiclesStateModel>): void {
        patchState({
            booking: null,
            bookingPhone: null,
            bookingStage: null,
            bookingCodeCheck: null,
        });
        dispatch(new VehiclesLoadActions(null)).subscribe();
    }
    @Action(VehiclesBookingPhoneRetryActions)
    bookingPhoneRetryActions({ patchState }: StateContext<VehiclesStateModel>): void {
        patchState({
            bookingPhone: null,
            bookingStage: null,
            bookingCodeCheck: null,
        });
    }
}
