import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import { AppRoutingModule } from './app.routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppState } from './core/store/app.state';
import { AuthState } from './core/auth/store/auth.state';
import { environment } from '../environments/environment';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { StatesChanges } from './shared/utils/stateChanges.const';
import { AppHttpInterceptor } from './app-http.interceptor';
import { AuthQueryService } from './core/auth/auth-query.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MessageBarModule } from './shared/message-bar/message-bar.module';
import { UpdaterModule } from './core/updater/updater.module';
import { VehiclesState } from './pages/vehicles/store/vehicles.state';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { CheckBookingPhoneService } from './shared/services/check-booking-phone.service';

registerLocaleData(localeRu);
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        RouteHandlerModule,
        MessageBarModule,
        MatSnackBarModule,
        NgxsModule.forRoot([ AppState, AuthState, VehiclesState ], {
            developmentMode: !environment.production,
        }),
        NgxsLoggerPluginModule.forRoot({
            collapsed: false,
            disabled: environment.production,
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgxsStoragePluginModule.forRoot({
            key: [ 'auth' ],
            migrations: StatesChanges.STATES_CHANGES_STORE,
        }),
        MatButtonModule,
        MatIconModule,
        UpdaterModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),


    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        AuthQueryService,

        { provide: LOCALE_ID, useValue: 'ru-RU' },
        CheckBookingPhoneService,
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule { }
