import {BookingMode, CurrView} from './models.interfaces';

export class ModelsGetAction {
  public static readonly type = '[Models] get models';
}

export class ModelsSetActiveAction {
  public static readonly type = '[Models] set active';

  constructor(public payload: string) {
  }
}

export class ModelsGetEquipmentsAction {
  public static readonly type = '[Models] get equipments';

  constructor(public payload: string) {
  }
}

export class ModelsSetActiveEquipmentAction {
  public static readonly type = '[Models] set active equipments';

  constructor(public payload: string) {
  }
}

export class ModelsSetActiveEngineAction {
  public static readonly type = '[Models] set active engine';

  constructor(public payload: string) {
  }
}


export class ModelsSetActiveColorAction {
  public static readonly type = '[Models] set active color';

  constructor(public payload: string) {
  }
}

export class ModelsSetActiveInteriorAction {
  public static readonly type = '[Models] set active interior';

  constructor(public payload: string) {
  }
}

export class ModelsOpenBookingStepAction {
  public static readonly type = '[Models] open booking step';
}

export class ModelsCloseBookingStepAction {
  public static readonly type = '[Models] close booking step';
}

export class ModelsBookingAction {
  public static readonly type = '[Models] booking';

  constructor(public payload: string) {
  }
}

export class ModelsBookingCompleteAction {
  public static readonly type = '[Models] booking complete';
}

export class ModelsBookingCompleteCodeValidateAction {
  public static readonly type = '[Models] booking code validate';
    constructor(public payload: string) {
    }
}

export class ModelsChangeViewAction {
  public static readonly type = '[Models] changeView';
  constructor(public payload: CurrView) {}
}

export class ModelsBookingRetryAction {
  public static readonly type = '[Models] retry booking';
}

export class ModelsBookingNewNumberAction {
  public static readonly type = '[Models] booking new number';
}

export class ModelsBookingModeAction {
  public static readonly type = '[Models] booking mode set';
    constructor(public payload: BookingMode) {
    }
}

export class ModelsBookingQueryFinishAction {
  public static readonly type = '[Models] booking query finish';
    // constructor(public payload: BookingMode) {
    // }
}
