import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {environment} from '../environments/environment';
import {AuthLogoutAction} from './core/auth/store/auth.actions';
import {AuthState} from './core/auth/store/auth.state';
import {MessageBarService} from './shared/message-bar/message-bar.service';
import {ModelsBookingQueryFinishAction} from './pages/models/store/models.state.action';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private store: Store,
        // private _snackBar: MatSnackBar,
        private messageBarService: MessageBarService
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const accessToken = this.store.selectSnapshot(AuthState.access_token);
        const headers = request.headers
            .append('x-project', 'genesis-sale')
            .append('Authorization', 'Bearer ' + this.store.selectSnapshot(AuthState.access_token));

        const externalUrl = new RegExp('^https://');
        const customReq = request.clone({
            url: externalUrl.test(request.url) ? request.url : environment.apiUrl + request.url,
            headers
        });

        return next.handle(customReq).pipe(
            map(data => {

                return data;
            }),
            catchError(error => {
                this.store.dispatch(new ModelsBookingQueryFinishAction());
                if (error.status === 401 && accessToken !== null) {
                    this.messageBarService.warn('Сессия истекла');
                    this.store.dispatch(new AuthLogoutAction());
                } else if (error.status === 504) {
                    this.messageBarService.warn('Проблемы с сетью. Повторите попытку.');

                } else if (error.status === 500) {
                    this.messageBarService.warn('Внутренняя ошибка сервера.');
                }
                throw error;
            })
        );
    }
}
