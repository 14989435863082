import {Injectable} from '@angular/core';
import {AuthLoginForm, AuthProfileResponse, AuthResponse} from './auth.interfaces';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AuthQueryService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  login(payload: AuthLoginForm): Observable<AuthResponse>{
    const url = '/auth/login';
    return this.httpClient
      .post<AuthResponse>(url, {username: payload.login, password: payload.password});
  }

  profile(): Promise<AuthProfileResponse | any> {
    const url = '/auth/profile';
    return this.httpClient
      .get(url).toPromise();
  }
}
