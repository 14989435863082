import { BookingMode } from '../../pages/models/store/models.interfaces';

export enum ProductType {
    Genesis = 'GENESIS',
    GenesisSale = 'GENESIS_SALE'
}

export const productListType = (mode: BookingMode): string => {
    return mode === 'sale' ? ProductType.GenesisSale : ProductType.Genesis;
};
