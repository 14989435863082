import {Injectable, isDevMode} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngxs/store';
import {SwUpdate} from '@angular/service-worker';
import {UpdaterComponent} from './updater.component';

@Injectable()
export class UpdaterService {

    private snackBarDNotOpen = true;

    constructor(
        private snackBar: MatSnackBar,
        private store: Store,
        private swUpdate: SwUpdate,
    ) {
    }

    init(): void {
        this.swUpdate.available.subscribe(() => {
            this.openUpdateBar();
        });
        if (!isDevMode()) {
            setInterval(() => {
                this.swUpdate.checkForUpdate().then();
            }, 60000);
        }

    }

    openUpdateBar(): void {
        this.snackBar.openFromComponent(UpdaterComponent, {
            panelClass: 'updateMessage',
            verticalPosition: 'top',
            horizontalPosition: 'center',
        });
        this.snackBarDNotOpen = false;
    }
}
