import { Injectable } from '@angular/core';
import { BookingMode, VBookingCodeCheck } from '../../pages/models/store/models.interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import { productListType } from '../mapper/product-list-type.mapper';
import { Observable } from 'rxjs';
import { CheckBookingClientStatus, CheckBookingClientStatusData } from '../models/check-booking.interface';
import { map } from 'rxjs/operators';
import { HttpParamEncoder } from '../utils/http-param-encoder';

@Injectable()
export class CheckBookingPhoneService {
    constructor(
        private httpClient: HttpClient,
    ) {
    }

    bookingCheckPhone(
        phone: string,
        mode: BookingMode
    ): Observable<VBookingCodeCheck> {
        return this.httpClient
                .get<VBookingCodeCheck>('/sale-mgr/booking/' + phone, {
            params: {
                product: productListType(mode),
            },
        });
    }

    // Получаем статус клиента по телефону
    findClientStatus(
        phone: string
    ): Observable<CheckBookingClientStatus> {
        const params = new HttpParams(
            { encoder: new HttpParamEncoder() }
        ).set('phone', phone);

        return this.httpClient
                .get<CheckBookingClientStatusData>(
            '/customers/findClientStatus',
            { params }
        ).pipe(
            map(({ data }) => data)
        );
    }
}
