import {Vehicle} from './vehicles.interface';

export class VehiclesLoadActions {
    public static readonly type = '[Vehicles] load';

    constructor(public payload: string) {
    }
}

export class VehiclesSelectActions {
    public static readonly type = '[Vehicles] select';

    constructor(public payload: Vehicle) {
    }
}
export class VehiclesBookingActions {
    public static readonly type = '[Vehicles] booking';

    constructor(public payload: Vehicle) {
    }
}
export class VehiclesBookingCancelActions {
    public static readonly type = '[Vehicles] booking cancel';

}

export class VehiclesSetModelFilterActions {
    public static readonly type = '[Vehicles] set model filter';

    constructor(public payload: string) {
    }
}

export class VehiclesSetColorFilterActions {
    public static readonly type = '[Vehicles] set color filter';

    constructor(public payload: string) {
    }
}
export class VehiclesSetComplicationsFilterActions {
    public static readonly type = '[Vehicles] set complications filter';

    constructor(public payload: string) {
    }
}

export class VehiclesSearchActions {
    public static readonly type = '[Vehicles] search';

    constructor(public payload: string) {
    }
}
export class VehiclesBookingSetPhoneActions {
    public static readonly type = '[Vehicles] booking set phone';

    constructor(public payload: string) {
    }
}
export class VehiclesBookingSmsValidateActions {
    public static readonly type = '[Vehicles] booking sms validate';

    constructor(public payload: string) {
    }
}
export class VehiclesBookingCompleteActions {
    public static readonly type = '[Vehicles] booking complete';

}
export class VehiclesBookingRetryActions {
    public static readonly type = '[Vehicles] booking retry';

}
export class VehiclesBookingPhoneRetryActions {
    public static readonly type = '[Vehicles] booking phone retry';

}

