import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteHandlerComponent } from './route-handler.component';
import { RouterModule } from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
  declarations: [RouteHandlerComponent],
  exports: [RouteHandlerComponent]
})
export class RouteHandlerModule { }
