import {Component} from '@angular/core';
import {Store} from '@ngxs/store';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppUpdateAction} from '../store/app.state.actions';

@Component({
  template:
      `
    <div fxLayout="row" fxLayoutAlign="start center" class="updater-box">
      <span class="title-text">Доступно обновление.</span>
      <div fxFlex="auto"></div>
      <button mat-flat-button class="update-btn" (click)="update()">Обновить</button>
      <div fxFlex="2rem"></div>
      <button mat-icon-button class="close-btn" (click)="close()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>

  `,
  styles: [
    `.updater-box{
      position: relative;
    }`,
      `
      .title-text {
        margin-right: .7rem;
      }
    `,
      `
      .update-btn {
      }
    `
    ,
      `
      .close-btn {
        position: absolute;
        color: #000000;
        top: -18px;
        right: -18px;
      }
    `,
      `
      .close-icon {
        color: #ffffff77;
      }`
  ]
})

export class UpdaterComponent {


  constructor(
    private snackBar: MatSnackBar,
    private store: Store,
  ) {
  }


  update(): void {
    this.store.dispatch(new AppUpdateAction());
  }

  close(): void {
    this.snackBar._openedSnackBarRef.dismiss();
  }
}
