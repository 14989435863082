import {NgModule} from '@angular/core';

import {UpdaterComponent} from './updater.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {UpdaterService} from './updater.service';

@NgModule({
  imports: [
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [],
  declarations: [
    UpdaterComponent
  ],
  providers: [
    UpdaterService
  ],
})
export class UpdaterModule {
}
