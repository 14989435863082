import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Store} from '@ngxs/store';
import {AuthGetProfileAction} from './core/auth/store/auth.actions';
import {UpdaterService} from './core/updater/updater.service';

@Component({
  selector: 'gns-root',
  template: `
    <div id="gnsMain">
      <gns-mobility-route-handler></gns-mobility-route-handler>
      <button (click)="toggleFullscreen()" *ngIf="!openedFullScreen" mat-mini-fab class="float-button" color="primary"><img
        src="/assets/img/icon/fullscreen-24px.svg"
        alt="full screen"></button>
    </div>`,
  styles: [
      `.float-button {
      position: fixed;
      top: 30px;
      left: calc(50% - 20px);
      background: white;
    }`
  ]
})
export class AppComponent implements OnInit {
  elem: any;
  openedFullScreen = false;


  constructor(
    @Inject(DOCUMENT) private document: any,
    private store: Store,
    private upSrv: UpdaterService
  ) {
  }

  ngOnInit(): void {
    this.elem = document.documentElement;
    this.store.dispatch(new AuthGetProfileAction());
    this.upSrv.init();
  }



  toggleFullscreen(): void {
    if (this.openedFullScreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  openFullscreen(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
      this.openedFullScreen = true;
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
      this.openedFullScreen = true;
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
      this.openedFullScreen = true;
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
      this.openedFullScreen = true;
    }
  }

  /* Close fullscreen */
  closeFullscreen(): void {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
      this.openedFullScreen = false;
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
      this.openedFullScreen = false;
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
      this.openedFullScreen = false;
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
      this.openedFullScreen = false;
    }
  }


}
