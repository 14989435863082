import { Injectable} from '@angular/core';
import {MessageBarComponent} from './message-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class MessageBarService {
    constructor(
        private snackBar: MatSnackBar,
        // private cd: ChangeDetectorRef
        ) {
    }

    info(message: string, duration: number = 3000): void {
        this.message(message, duration, 'info-message', 'info');
    }

    good(message: string, duration: number = 3000): void {
        this.message(message, duration, 'good-message', 'info');
    }

    warn(message: string, duration: number = 3000): void {
        this.message(message, duration, 'warn-message', 'info');
    }

    private message(message: string, duration: number, panelClass: string, icon: string): void {
        this.snackBar.openFromComponent(MessageBarComponent, {
            data: {message, icon},
            panelClass,
            duration,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
        });
        // this.cd.detectChanges();
    }

}
