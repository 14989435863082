import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Router} from '@angular/router';
import {Injectable, NgZone} from '@angular/core';
import {
    AuthGetLinkAction,
    AuthGetProfileAction,
    AuthLoginAction,
    AuthLoginCompleteAction,
    AuthLogoutAction
} from './auth.actions';
import {AuthFio} from '../auth.interfaces';
import {AuthQueryService} from '../auth-query.service';

const INITIAL_AUTH_STATE = {
    email: null,
    userName: null,
    access_token: null,
    fio: {
        f_name: null,
        m_name: null,
        l_name: null
    },
    role: null,
    settings: null
};

export interface AuthStateModel {
    email: string;
    userName: string;
    fio: AuthFio;
    access_token: string;
    role: string;
    settings: any;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: INITIAL_AUTH_STATE
})
@Injectable()
export class AuthState {
    constructor(
        private router: Router,
        // private httpClient: HttpClient,
        private ngZone: NgZone,
        private authQueryService: AuthQueryService
    ) {
    }

    @Selector()
    public static isAuth(state: AuthStateModel): boolean {
        return !!state.access_token;
    }

    @Selector()
    public static userName(state: AuthStateModel): string {
        return !!state.fio.f_name && !!state.fio.l_name ? state.fio.f_name + ' ' + state.fio.l_name : state.userName;
    }

    @Selector()
    public static isNotAuth(state: AuthStateModel): boolean {
        return !state.access_token; // ? false : true;
    }

    @Selector()
    public static access_token(state: AuthStateModel): string {
        return state.access_token;
    }


    @Selector()
    public static email(state: AuthStateModel): string {
        return state.email;
    }

    @Action(AuthLogoutAction)
    public authLogout({setState}: StateContext<AuthStateModel>): void {
        setState(INITIAL_AUTH_STATE);

        // this.store.dispatch(new CustomerClearAction());


        // this.router.navigate(['/login']);
        window.location.reload();
    }

    @Action(AuthLoginAction)
    public authLogin(
        {getState, patchState, dispatch}: StateContext<AuthStateModel>,
        {payload}: AuthLoginAction
    ): void {

    }

    @Action(AuthLoginCompleteAction)
    public async loginCompleteAction(
        {getState, patchState, dispatch}: StateContext<AuthStateModel>,
        {payload}: AuthLoginCompleteAction
    ): Promise<void> {
        patchState({...payload});
        dispatch(new AuthGetProfileAction());
        this.ngZone.run(() => this.router.navigate(['/'])).then();

    }

    @Action(AuthGetLinkAction)
    getLinkAction(): void {
        this.ngZone.run(() => this.router.navigate(['/'])).then();
    }

    @Action(AuthGetProfileAction)
    async getProfileAction({getState, patchState}: StateContext<AuthStateModel>): Promise<void>  {
        const {access_token} = getState();
        if (!access_token) {
            return;
        }
        const profileResponse = await this.authQueryService.profile();
        const {
            email,
            userName,
            fio,
            role,
            settings,
        } = profileResponse;
        patchState({
            email,
            userName,
            fio,
            role,
            settings
        });
    }
}
