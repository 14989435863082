import { VBookingCodeCheck } from '../../pages/models/store/models.interfaces';
import { VehicleBookingResult } from '../../elements/client-booking-result/client-booking-status.interface';

export const setBookingStatus = (
    bookingCodeCheck: VBookingCodeCheck
): VehicleBookingResult => {
    if (bookingCodeCheck?.guid) {
        return { status: 'codeValidate' };
    }

    return {
        status: (bookingCodeCheck?.status === 'Number booked' ? 'numberBooked' : 'error'),
    };
};
