import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from './core/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./core/app-core.module').then(
                m => m.AppCoreModule
            ),
        // canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        data: {preload: false},
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./core/auth/auth.module').then(m => m.AuthModule),
        // canLoad: [NoAuthGuard],
    },
    {
        path: '**',
        redirectTo: '/main',
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'top',
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
