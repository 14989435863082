import {Component} from '@angular/core';
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';


@Component({
    selector: 'gns-mobility-route-handler',
    template: '<router-outlet></router-outlet>',
    animations: [fadeInOnEnterAnimation({duration: 300}),
        fadeOutOnLeaveAnimation({duration: 300})]
})
export class RouteHandlerComponent {}
