import {AuthLoginForm, AuthResponse} from '../auth.interfaces';


// export class AuthProfileAction {
//   public static readonly type = '[Auth] get profile';
//   constructor(public payload: string) {}
// }
export class AuthLogoutAction {
  public static readonly type = '[Auth] Logout';
}

export class AuthLoginAction {
  public static readonly type = '[Auth] Login';
  constructor(public payload: AuthLoginForm) {}
}
export class AuthLoginCompleteAction {
  public static readonly type = '[Auth] Login complete';
  constructor(public payload: AuthResponse) {}
}

export class AuthGetLinkAction {
  public static readonly type = '[Auth] get restore link';
  constructor(public payload: AuthLoginForm) {}
}

export class AuthGetProfileAction {
  public static readonly type = '[Auth] get Profile';

}
