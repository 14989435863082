import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';

interface MessageBarData {
  message: string;
  icon: string;
}

@Component({
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.3em">
      <mat-icon>{{data.icon}}</mat-icon>
      <div class="message-text">{{data.message}}</div>
      <div fxFlex="auto"></div>
      <div fxLayout="row" fxLayoutAlign="end center">
      </div>
      <button mat-icon-button style="position: relative; top: -18px;right: -18px;" (click)="close()">
        <mat-icon style="color: #444444;">close</mat-icon>
      </button>
    </div>`,
})

export class MessageBarComponent {

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: MessageBarData
  ) {
  }

  close(): void {
    this.snackBar._openedSnackBarRef.dismiss();
  }
}
