export namespace StatesChanges {
    export const STATES_CHANGES_STORE = [

        // {
        //     version: 5,//or undefined
        //     key: 'customers',
        //     versionKey: 'stateVersion',
        //     migrate: (state) => {
        //         return {
        //             ...state,
        //             displayedCountersBoxes: [],
        //
        //             stateVersion: 6,
        //             customersStateVersion: 6 // Important to set this to the next version!
        //         };
        //     }
        // },


    ];
}
