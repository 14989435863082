export enum ClientStatus {
    Refused = 0, // Отказано
    PhotoExpected1, // Ожидается фото
    WaitingForVerification = 2, // Ожидается проверка
    NewPhotoExpected = 3, // Ожидается новые фото
    Active = 4, // Активен
    Check = 5, // Проверка
    ExperienceBelowSafe = 8 // Возраст/стаж ниже требований
}

export interface CheckBookingClientStatus {
    status: ClientStatus;
    phone: string;
}

export interface CheckBookingClientStatusData {
    data: CheckBookingClientStatus;
}
